<template>
  <v-card outlined>
    <div
      class="text--secondary white px-1"
      style="left: 12px; position: absolute; top: -10px; font-size: 12px;"
    >
      {{ label }}
    </div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-text-field
            type="number"
            :label="$t('amount')"
            :filled="filled"
            :hide-details="hideDetails"
            :value="price.amount"
            @input="updateAmount"
          />
        </v-col>
        <v-col>
          <CurrencySelection
            :selected-currency="price.currency_code"
            :filled="filled"
            :label="$t('currency')"
            :hide-details="hideDetails"
            v-on="$listeners"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import CurrencySelection from "@/components/common/pricing/CurrencySelection"

export default {
  name: "Price",
  components: {
    CurrencySelection
  },
  props: {
    price: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateAmount(value) {
      this.$emit('updateAmount', Number(value))
    }
  }
}
</script>

<style scoped>

</style>